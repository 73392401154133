// Here you can add other styles
.rc-time-picker-input{
    min-height: 35px;
}
.custom-modal-body + button{
    right: -35px;
}
.submitleader{
    padding-top:27px;
}

.vscont{
    top:40px
}
.vstext{
    font-size: 40px;
}
.pad-top{
    padding-top:12px ;
}
// .pagination{
//     .page-item:first-child{
//         .page-link::before{
//             content: '|';
//             margin-right: 4px;
//             font-size: 13px;
//             vertical-align: 1px;
//         }
//     }
//     .page-item:last-child{
//         .page-link::after{
//             content: '|';
//             margin-left: 4px;
//             font-size: 13px;
//             vertical-align: 1px;
//         }
//     }
// }